import React, { PureComponent } from "react";
import PrintArea from "../../../common/component/PrintArea";
import { withTranslation, WithTranslation } from "react-i18next";
import Simple from "../SalesPrintTemplate/Simple";
import Standard from "../SalesPrintTemplate/Standard";

type SalesPrintState = {
    data: any;
    companyInfo: any;
    printSetting: any;
    colSpan: number;
};

class SalesPrint extends PureComponent<WithTranslation, SalesPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("sales-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        localStorage.removeItem("sales-print");

        // console.log(jsonData);
        let stateObj = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            colSpan: 4,
            companyInfo: jsonData && jsonData.companyInfo ? jsonData.companyInfo : null,
            printSetting: jsonData && jsonData.printSetting ? jsonData.printSetting : null,
        }

        // check if any product with unitConversion
        let hasAnyUnitConv = stateObj.data?.detailList?.find((val: any) => val.unitConversion !== null);
        if (hasAnyUnitConv) stateObj.colSpan = 5;

        this.state = stateObj;
    }

    render() {
        const { companyInfo, data } = this.state;
        return (
            <PrintArea>
                {this.state.printSetting?.printTemplate === "STANDARD" ? (
                    <Standard data={data} companyInfo={companyInfo} colSpan={this.state.colSpan} />
                ) : (
                    <Simple data={data} companyInfo={companyInfo} />
                )}
            </PrintArea>
        );
    }
}

export default withTranslation()(SalesPrint);
