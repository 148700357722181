import dayjs from "dayjs";
import SafeBig from "../function/safeBig";
import Big from "big.js";
import reduxStore from "../reduxHandler/createStore";
import { slcSettingRoundFeature_Value, slcSettingShowDecimalFeature_Value } from "../../Setting/redux/setting";
// import { modifyNumber } from "./valueModifierHelper";

//accounting-js has no typings
// declare let require: (moduleId: string) => any;
// let accounting = require("accounting-js");

/**
 * ShowDate
 * Fungsi untuk menampilkan date dengan format yang diinginkan
 *
 * @param {string} date parameter dari API
 * @param {string} format full format see dayjs
 */
export function showDate(date: any, format: string | null = null) {
    if (date == null) return "--";
    if (!format) format = "DD/MM/YYYY"; //TO-DO: Change to follow user-setting
    return dayjs(date).format(format);
}

/**
 * ShowCurrency
 * Fungsi untuk menampilkan display angka dalam format locale.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 *
 * - Check setting for rounding & showDecimal setting
 * - Round if neccessary
 * - Format using `Intl`
 * - It's possible to include currency symbol
 */
export function showCurrency(amount: number | Big | string = 0, precision: number = 2, ignoreNegative = false) {
    if (!Number.isFinite(Number(amount))) return "x";

    //read redux config for round feature
    const roundFeature = slcSettingRoundFeature_Value(reduxStore.getState());
    const showDecimal = slcSettingShowDecimalFeature_Value(reduxStore.getState());
    let tAmount: Big = SafeBig(0);
    if (roundFeature === true && showDecimal === false) {
        //override rounding
        precision = 0;
        tAmount = SafeBig(amount ? amount : 0).round();
    } else {
        //else force rounding up to `precision` decimal
        tAmount = SafeBig(amount ? amount : 0).round(precision);
    }

    //if negative, we need to add () later
    let isNegative = !ignoreNegative && tAmount.lt(0);

    //convert negative to positive
    if (tAmount.lt(0)) {
        tAmount = tAmount.times(-1);
    }

    //read decimal & thousand separator from user-setting |TO-DO
    // let decimal = ",";
    // let thousand = ".";

    // return accounting.formatMoney(tAmount, {
    //     symbol: " ",
    //     decimal,
    //     precision,
    //     thousand,
    //     format: { pos: "%s %v", neg: "%s (%v)", zero: "%s --" },
    // });

    let format = Intl.NumberFormat("id", {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
        // currency: "IDR",
        // style: "currency",
    }); //TO-DO read from setting

    if (SafeBig(tAmount).eq(0)) return "--";
    if (isNegative) return `(${format.format(Number(tAmount))})`;

    return format.format(Number(tAmount));
}

/**
 * ShowQty
 * Fungsi untuk menampilkan display angka dalam format locale.
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 *
 * Notes:
 * - Not checking round setting (as we don't want to round qty)
 * - Can set maxPrecision, but not minPrecision
 */
export function showQty(qty: number | Big | string = 0, maxPrecision: number = 2) {
    if (!Number.isFinite(Number(qty))) return "x";

    let tQty = Number(qty);

    if (SafeBig(tQty).eq(0)) return "--";

    let format = Intl.NumberFormat("id", { maximumFractionDigits: maxPrecision }); //TO-DO read from setting
    return format.format(tQty);
}

/**
 * Say
 * Fungsi untuk mengeja integer ke string
 * @param {Number} amount
 */
export function say(amount: number | string) {
    //check languange from user setting
    if (typeof amount === "string") {
        amount = Number(amount);
    }

    //if indonesia
    return sayIndonesia(amount).trim();
}

/**
 * Say for Indonesia Language
 * @param {Number} amount
 */
function sayIndonesia(amount: number) {
    let angka = [
        "",
        "Satu",
        "Dua",
        "Tiga",
        "Empat",
        "Lima",
        "Enam",
        "Tujuh",
        "Delapan",
        "Sembilan",
        "Sepuluh",
        "Sebelas",
    ];
    let temp = "";
    amount = Math.floor(amount);
    if (amount < 12) temp = " " + angka[amount];
    else if (amount < 20) temp = sayIndonesia(amount - 10) + " Belas";
    else if (amount < 100) temp = sayIndonesia(amount / 10) + " Puluh" + sayIndonesia(amount % 10);
    else if (amount < 200) temp = " seratus" + sayIndonesia(amount - 100);
    else if (amount < 1000) temp = sayIndonesia(amount / 100) + " Ratus" + sayIndonesia(amount % 100);
    else if (amount < 2000) temp = " seribu" + sayIndonesia(amount - 1000);
    else if (amount < 1000000) temp = sayIndonesia(amount / 1000) + " Ribu" + sayIndonesia(amount % 1000);
    else if (amount < 1000000000) temp = sayIndonesia(amount / 1000000) + " Juta" + sayIndonesia(amount % 1000000);
    else if (amount < 1000000000000)
        temp = sayIndonesia(amount / 1000000000) + " Milyar" + sayIndonesia(amount % 1000000000);
    else if (amount < 1000000000000000)
        temp = sayIndonesia(amount / 1000000000000) + " Trilyun" + sayIndonesia(amount % 1000000000000);
    return temp;
}

/**
 * CalculateTaxRatio
 * Fungsi untuk menghitung rasio tax yang digunakan untuk pengalian / pembagian total.
 *
 * @param {Double} tax total in percent (10 / 20 / 30)
 * @return {Big} TaxRatio
 */
export function calculateTaxRatio(tax: number) {
    let tmp = SafeBig(tax ? tax : 0);
    return tmp.div(100).plus(1);
}
