import React, { Component } from "react";
import { Route } from "react-router-dom";

// component
import { AuthCheck } from "../../common/component/AuthCheck";
import SalesPrint from "../../Register/Sales/SalesPrint";
import DeliveryReportPrint from "../../Register/Sales/DeliveryReportPrint";

/**
 * PrintPage uses new window, not inside app, so need to define routing here
 */
class PrintRouter extends Component {
    render() {
        return (
            <>
                <AuthCheck moduleName="sales" allowedPermission={["read", "readAmount"]}>
                    <Route exact path="/print-page/sales" component={SalesPrint} />
                </AuthCheck>
                <AuthCheck moduleName="sales" allowedPermission={["readQty"]}>
                    <Route exact path="/print-page/delivery-report" component={DeliveryReportPrint} />
                </AuthCheck>
            </>
        );
    }
}

export default PrintRouter;
