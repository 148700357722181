import React from "react";
import { useTranslation } from "react-i18next";
import changeCase from "change-case";
import { showDate } from "../../function/showValueHelper";

type PrintHeaderProps = {
    title: string;
    companyInfo: any;
    supplier?: any;
    customer?: any;
    key?: string;
    no?: string;
    dtCreated: string;
};

function getData(companyInfo: any, key: string) {
    let obj = companyInfo.find((val: any) => val.key === key);
    return obj ? obj.value : "";
}

const PrintHeader = React.memo(({ title, companyInfo, supplier, customer, no, dtCreated }: PrintHeaderProps) => {
    const { t } = useTranslation();
    let to = supplier != null ? supplier : customer;
    return (
        <thead>
            <tr>
                <th colSpan={2} id="docTitle">
                    {changeCase.upper(title)}
                </th>
            </tr>
            <tr style={{ marginBottom: "10px" }}>
                <th style={{ width: "60%" }}>
                    <b>
                        {t("common.documentNo")}
                        <br />
                        {no}
                    </b>
                </th>
                <th>
                    <b>
                        {t("common.createDate")}
                        <br />
                        {showDate(dtCreated)}
                    </b>
                </th>
            </tr>
            <tr>
                <th />
                <th>{t("common.recipient")}:</th>
            </tr>
            <tr>
                <th>{getData(companyInfo, "nama-perusahaan")}</th>
                <th>{to.name}</th>
            </tr>
            <tr>
                <th>
                    {getData(companyInfo, "alamat-perusahaan")}
                    {getData(companyInfo, "kota") ? ", " + getData(companyInfo, "kota") + "." : ""}
                </th>
                <th>
                    {to.address}
                    {to.city ? ", " + to.city + "." : ""}
                </th>
            </tr>
            <tr>
                <th>
                    {getData(companyInfo, "status-badan-usaha") === "PKP"
                        ? t("common.taxIdentificationNo") + ": " + getData(companyInfo, "no-npwp")
                        : ""}
                </th>
                <th>
                    {t("common.taxIdentificationNo")}: {to.useTax ? to.taxIdentificationNo : ""}
                </th>
            </tr>
            <tr>
                <th>
                    {t("common.email")}: {getData(companyInfo, "email")}
                </th>
                <th>
                    {t("common.email")}: {to.email ? to.email : ""}
                </th>
            </tr>
            <tr>
                <th>
                    {t("common.telephoneNo")}: {getData(companyInfo, "no-telepon")}
                </th>
                <th>
                    {t("common.telephoneNo")}: {to.phone ? to.phone : ""}
                </th>
            </tr>
        </thead>
    );
});

export default PrintHeader;
