import React from "react";
import lazyWithRetry from "./common/function/lazyWithRetry";

/**
 * ALL Module index/sidebar/lazy MUST BE DEFINED HERE.
 * The SidebarLinks and <Route> components are handled by EnsureLoginPage.
 *
 * However, sub-pages of the Module need to be defined in their own index file (as we're not using lazy-load there).
 *
 * Paths should avoid substring to avoid sidebar-active bug
 *
 * **NOTES**
 * There are 2 moduleConfig here, refineAccounting and refineInventory.
 * Set them both if there's any change.
 *
 * By separating them like this, build output is smaller for refineInventory (as the number of import is smaller).
 */

let moduleConfig: RouteDefinitionArray = [];

//lazy load modules
//usage: component: ()=><MasterBank/>, //lazy is an object, we need to simulate call here
// const Dashboard = lazy(() => import("./Dashboard"));
// const MasterCoa = lazy(() => import("./Master/Coa"));
// const MasterCustomer = lazy(() => import("./Master/Customer"));
// const MasterWarehouse = lazy(() => import("./Master/Warehouse"));
// const MasterProduct = lazy(() => import("./Master/Product"));
const MasterAdmin = lazyWithRetry(() => import("./Master/Admin"));
const MasterTenant = lazyWithRetry(() => import("./Master/Tenant"));
const Sales = lazyWithRetry(() => import("./Register/Sales"));
const InventoryFlow = lazyWithRetry(() => import("./Register/InventoryFlow"));
const Setting = lazyWithRetry(() => import("./Setting"));
// const Test = lazy(() => import("./Test"));
moduleConfig = [
    // {
    //     path: "/home",
    //     label: "common.dashboard",
    //     icon: "tachometer-alt",
    //     addMargin: true,
    //     component: () => <Dashboard />,
    // },
    {
        authorityModule: "inventory-flow",
        authorityType: ["read", "readAmount", "readQty"],
        icon: "cubes",
        path: "/stock-balance",
        label: "common.stockBalance",
        addMargin: true,
        component: () => <InventoryFlow />,
    },
    {
        authorityModule: "sales",
        authorityType: ["read", "readAmount"],
        icon: "handshake",
        path: "/sales",
        label: "common.sales",
        addMargin: true,
        component: () => <Sales />,
    },
    // {
    //     authorityModule: ["coa", "bank", "cash", "product", "warehouse", "supplier", "customer", "term-of-payment"],
    //     authorityType: ["read"],
    //     label: "common.master",
    //     icon: "book",
    //     collapsible: true,
    //     stateName: "master",
    //     children: [
    //         {
    //             authorityModule: "coa",
    //             path: "/coa",
    //             label: "common.coa",
    //             shortLabel: "short.coa",
    //             component: () => <MasterCoa />,
    //         },
    //         {
    //             authorityModule: "warehouse",
    //             path: "/warehouse",
    //             label: "common.warehouse",
    //             shortLabel: "short.warehouse",
    //             component: () => <MasterWarehouse />,
    //         },
    //         {
    //             authorityModule: ["customer", "customer-category"],
    //             label: "common.customer",
    //             icon: "users",
    //             collapsible: true,
    //             stateName: "masterCustomer",
    //             path: "/customer",
    //             shortLabel: "short.customer",
    //             component: () => <MasterCustomer />,
    //         },
    //         {
    //             authorityModule: [
    //                 "product",
    //                 "product-category",
    //                 "product-special-price",
    //                 "product-type",
    //                 "product-unit",
    //                 "product-unit-conversion",
    //             ],
    //             label: "common.product",
    //             icon: "cube",
    //             collapsible: true,
    //             stateName: "masterProduct",
    //             children: [
    //                 {
    //                     authorityModule: "product",
    //                     path: "/product",
    //                     label: "common.product",
    //                     shortLabel: "short.product",
    //                     component: () => <MasterProduct />,
    //                 },
    //                 {
    //                     authorityModule: "product-special-price",
    //                     path: "/product-special-price",
    //                     label: "common.productSpecialPrice",
    //                     shortLabel: "short.productSpecialPrice",
    //                     component: () => <MasterProduct />,
    //                 },
    //                 {
    //                     authorityModule: "product-category",
    //                     path: "/product-category",
    //                     label: "common.productCategory",
    //                     shortLabel: "short.productCategory",
    //                     component: () => <MasterProduct />,
    //                 },
    //                 // { //currently hidden
    //                 //     authorityModule: "product-type", //missing authority
    //                 //     path: "/product-types",
    //                 //     label: "common.masterProductType",
    //                 //     shortLabel: "short.productType",
    //                 //     component: () => <MasterProduct />,
    //                 // },
    //                 {
    //                     authorityModule: "product-unit",
    //                     path: "/product-unit",
    //                     label: "common.productUnit",
    //                     shortLabel: "short.productUnit",
    //                     component: () => <MasterProduct />,
    //                 },
    //                 {
    //                     authorityModule: "product-unit-conversion",
    //                     path: "/product-unit-conversion",
    //                     label: "common.productUnitConversion",
    //                     shortLabel: "short.productUnitConversion",
    //                     component: () => <MasterProduct />,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     authorityModule: ["sales"],
    //     authorityType: ["read", "readQty", "readAmount"],
    //     label: "common.salesModule",
    //     icon: "handshake",
    //     collapsible: true,
    //     stateName: "salesModule",
    //     children: [
    // {
    //     authorityModule: "sales",
    //     authorityType: ["readQty"],
    //     path: "/delivery-report",
    //     label: "common.deliveryReport",
    //     shortLabel: "short.deliveryReport",
    //     component: () => <Sales />,
    // },
    //         {
    //             authorityModule: "sales",
    //             authorityType: ["readAmount"],
    //             path: "/sales",
    //             label: "common.sales",
    //             shortLabel: "short.sales",
    //             component: () => <Sales />,
    //         },
    //     ],
    // },
    // {
    //     authorityModule: ["inventory-flow"],
    //     label: "common.inventoryModule",
    //     icon: "cubes",
    //     collapsible: true,
    //     stateName: "inventoryModule",
    //     children: [
    //         {
    //             authorityModule: "inventory-flow",
    //             path: "/inventory-warehouse-detail",
    //             label: "common.inventoryWarehouseTable",
    //             shortLabel: "short.inventoryWarehouseTable",
    //             component: () => <InventoryFlow />,
    //         },
    //     ],
    // },
    {
        authorityModule: ["admin", "admin-role", "tenant"],
        label: "common.accountModule",
        icon: "users",
        collapsible: true,
        stateName: "accountModule",
        children: [
            {
                authorityModule: "tenant",
                path: "/tenant",
                label: "common.tenant",
                shortLabel: "short.tenant",
                component: () => <MasterTenant />,
            },
            {
                authorityModule: "admin",
                path: "/admin",
                label: "common.admin",
                shortLabel: "short.admin",
                component: () => <MasterAdmin />,
            },
            {
                authorityModule: "role",
                path: "/role",
                label: "common.role",
                shortLabel: "short.role",
                component: () => <MasterAdmin />,
            },
            {
                authorityModule: "tenant",
                path: "/role-admin",
                label: "common.roleAdmin",
                shortLabel: "short.roleAdmin",
                component: () => <MasterAdmin />,
            },
        ],
    },
    {
        authorityModule: ["setting"],
        authorityType: ["create", "read", "update"],
        label: "common.settingModule",
        icon: "sliders-h",
        collapsible: true,
        stateName: "settingModule",
        children: [
            {
                authorityModule: "setting",
                path: "/setting-info",
                label: "common.companyInfo",
                shortLabel: "short.companyInfo",
                component: () => <Setting />,
            },
            {
                authorityModule: "setting",
                path: "/setting-application",
                label: "common.application",
                shortLabel: "short.application",
                component: () => <Setting />,
            },
            {
                authorityModule: "setting",
                path: "/setting-accounting",
                label: "common.accounting",
                shortLabel: "short.accounting",
                component: () => <Setting />,
            },
        ],
    },
];

export default moduleConfig;
