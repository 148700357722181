/**
 * Tutorial/documentation modules
 */

let tutorialConfig: TutorialDefinitionMap = {};

if (process.env.REACT_APP_IS_INVENTORY === "false") {
    //lazy load modules
    //usage: component: ()=><MasterBank/>, //lazy is an object, we need to simulate call here

    tutorialConfig = {
        "purchase-order/list": {
            title: "common.purchaseOrder",
            url: "https://refineaccounting.com/fitur-kami/",
            // videoId: "xZCxav3v7QA",
            // component:  <PurchaseOrderListTutorial/>
        },
    };
} else {
    //ELSE IF INVENTORY
    //lazy load modules
    //usage: component: ()=><MasterBank/>, //lazy is an object, we need to simulate call here
    // const Dashboard = lazy(() => import("./Dashboard"));
    // tutorialConfig = {
    // };
}

export default tutorialConfig;
