import reducerRegistry from "../../../common/reduxHandler/reducerRegistry";
import { CACHE_MODE } from "../../../common/function/request";
import { createSelector } from "reselect";
import {
    setFetchWithPaginationParam,
    setFetchAllParam,
    setCreateEditParam,
} from "../../../common/function/requestParamHelper";
import { deleteCacheCollection } from "../../../common/function/cache";
import { reqAdminGet, reqAdminPost, reqAdminDelete, reqAdminPut } from "../../../common/function/adminRequest";

//initial state
const initialState: CommonReduxState = {
    loading: false,
    data: null,
    error: null,
};

//action
const createActionName = (name: string) => `tenant/${name}`;
const FETCH_TENANT_LIST = createActionName("FETCH_TENANT_LIST");
const FETCH_TENANT_LIST_DONE = createActionName("FETCH_TENANT_LIST_DONE");
const FETCH_TENANT_LIST_FAILED = createActionName("FETCH_TENANT_LIST_FAILED");
const OPTION_ALL_TENANT = createActionName("OPTION_ALL_TENANT");
const OPTION_ALL_TENANT_DONE = createActionName("OPTION_ALL_TENANT_DONE");
const OPTION_ALL_TENANT_FAILED = createActionName("OPTION_ALL_TENANT_FAILED");
const CREATE_TENANT = createActionName("CREATE_TENANT");
const CREATE_TENANT_DONE = createActionName("CREATE_TENANT_DONE");
const CREATE_TENANT_FAILED = createActionName("CREATE_TENANT_FAILED");
const UPDATE_TENANT = createActionName("UPDATE_TENANT");
const UPDATE_TENANT_DONE = createActionName("UPDATE_TENANT_DONE");
const UPDATE_TENANT_FAILED = createActionName("UPDATE_TENANT_FAILED");
const FETCH_TENANT = createActionName("FETCH_TENANT");
const FETCH_TENANT_DONE = createActionName("FETCH_TENANT_DONE");
const FETCH_TENANT_FAILED = createActionName("FETCH_TENANT_FAILED");
const DELETE_TENANT = createActionName("DELETE_TENANT");
const DELETE_TENANT_DONE = createActionName("DELETE_TENANT_DONE");
const DELETE_TENANT_FAILED = createActionName("DELETE_TENANT_FAILED");

//reducer
function tenantList(state = initialState, action: DispatchedAction<APIListResponse>) {
    switch (action.type) {
        case FETCH_TENANT_LIST:
            return { ...initialState, page: null, totalPage: null, loading: true };
        case FETCH_TENANT_LIST_DONE:
            return {
                ...state,
                data: action.payload.data.list,
                page: action.payload.data.paginator.page,
                totalPage: action.payload.data.paginator.totalPage,
                loading: false,
            };
        case FETCH_TENANT_LIST_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function optionAllTenant(state = initialState, action: DispatchedAction<APIListResponse>) {
    switch (action.type) {
        case OPTION_ALL_TENANT:
            return { ...initialState, loading: true };
        case OPTION_ALL_TENANT_DONE:
            return { ...state, data: action.payload.data.list, loading: false };
        case OPTION_ALL_TENANT_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function createTenant(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case CREATE_TENANT:
            return { ...initialState, loading: true };
        case CREATE_TENANT_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case CREATE_TENANT_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function getActiveTenant(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case FETCH_TENANT:
            return { ...initialState, loading: true };
        case FETCH_TENANT_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case FETCH_TENANT_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function updateTenant(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case UPDATE_TENANT:
            return { ...initialState, loading: true };
        case UPDATE_TENANT_DONE:
            return { ...state, data: action.payload.data, loading: false };
        case UPDATE_TENANT_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

function deleteTenant(state = initialState, action: DispatchedAction<APIBaseDataResponse>) {
    switch (action.type) {
        case DELETE_TENANT:
            return { ...initialState, loading: true };
        case DELETE_TENANT_DONE:
            return { ...state, data: action.payload, loading: false };
        case DELETE_TENANT_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

//register reducer
reducerRegistry.register("tenantList", tenantList);
reducerRegistry.register("optionAllTenant", optionAllTenant);
reducerRegistry.register("createTenant", createTenant);
reducerRegistry.register("getActiveTenant", getActiveTenant);
reducerRegistry.register("updateTenant", updateTenant);
reducerRegistry.register("deleteTenant", deleteTenant);

//selector
export const slcTenantList = (state: any): CommonReduxState => state["tenantList"];
export const slcCreateTenant = (state: any): CommonReduxState => state["createTenant"];
export const slcFetchActiveTenant = (state: any): CommonReduxState => state["getActiveTenant"];
export const slcUpdateTenant = (state: any): CommonReduxState => state["updateTenant"];
export const slcDeleteTenant = (state: any): CommonReduxState => state["deleteTenant"];

const getAllTenant = (state: any): any[] => (state["optionAllTenant"].data ? state["optionAllTenant"].data : []);
/**
 * Returns options directly, not redux-state
 */
export const slcOptionAllTenant = createSelector(
    getAllTenant, //basic selector
    (allTenant): { value: any; label: string; uuid: string }[] => allTenant.map((val: any) => val)
);

/**
 * Returns options directly, not redux-state
 */
// export const slcOptionAllTenant_Object = createSelector(
//     getAllTenant, //basic selector
//     (allTenant): { value: any; label: string }[] =>
//         allTenant.map((val: any) => ({ value: { ...val, type: "tenantId" }, label: val.name }))
// );

//action creator
const urlPath = "tenant";

/**
 * Action for Master Tenant pagination
 *
 * @param {*} param
 */
export function actFetchTenantList(param: APIGetParameter) {
    param = setFetchWithPaginationParam(param);
    const request = reqAdminGet(urlPath, param);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_TENANT_LIST,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_TENANT_LIST_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_TENANT_LIST_FAILED,
                    error,
                });
            });
    };
}

/**
 * Load all Master Tenant to be used as option
 */
export function actOptionAllTenant() {
    const param = setFetchAllParam();
    const request = reqAdminGet(`${urlPath}/option`, param, null, CACHE_MODE.WITH_LOAD_WITH_SAVE);

    return (dispatch: any) => {
        dispatch({
            type: OPTION_ALL_TENANT,
            addToLoading: true,
        });
        request
            .then((payload) => {
                dispatch({
                    type: OPTION_ALL_TENANT_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: OPTION_ALL_TENANT_FAILED,
                    error,
                });
            });
    };
}

/**
 * Create new Tenant
 *
 * @param Object valueList
 */
export function actCreateTenant(valueList: any) {
    let param = setCreateEditParam(valueList);

    const request = reqAdminPost(urlPath, param);

    return (dispatch: any) => {
        dispatch({
            type: CREATE_TENANT,
        });
        request
            .then((payload) => {
                //clear cache
                deleteCacheCollection(urlPath);
                dispatch({
                    type: CREATE_TENANT_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_TENANT_FAILED,
                    error,
                });
            });
    };
}

/**
 * Edit tenant data
 *
 * Row limitation and tenant exist in different DB
 *
 * @param integer id
 * @param object valueList
 */
export function actEditTenant(id: number, valueList: any) {
    let param = setCreateEditParam(valueList);

    const request = reqAdminPut(`${urlPath}/${id}`, param);

    return (dispatch: any) => {
        dispatch({
            type: UPDATE_TENANT,
        });
        request
            .then((payload) => {
                //clear cache
                deleteCacheCollection(urlPath);
                dispatch({
                    type: UPDATE_TENANT_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_TENANT_FAILED,
                    error,
                });
            });
    };
}

/**
 * Get a Tenant data. Used mainly for view/edit
 *
 * @param integer id
 */
export function actFetchActiveTenant(id: number) {
    const request = reqAdminGet(`${urlPath}/${id}`, null);

    return (dispatch: any) => {
        dispatch({
            type: FETCH_TENANT,
            addToLoading: true,
        });
        request
            .then((payload) => {
                dispatch({
                    type: FETCH_TENANT_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: FETCH_TENANT_FAILED,
                    error,
                });
            });
    };
}

/**
 * Delete Tenant from DB
 *
 * @param {number} id
 */
export function actDeleteTenant(id: number) {
    const request = reqAdminDelete(`${urlPath}/${id}`);

    return (dispatch: any) => {
        dispatch({
            type: DELETE_TENANT,
        });
        request
            .then((response) => {
                //clear cache
                deleteCacheCollection(urlPath);
                dispatch({
                    type: DELETE_TENANT_DONE,
                    payload: true,
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_TENANT_FAILED,
                    error,
                });
            });
    };
}
