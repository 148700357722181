import React, { useState } from "react";
import ClassNames from "classnames";
import { UncontrolledTooltip, Input, FormGroup, Label, Col, InputGroup, InputGroupAddon } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type InputTextAreaType = BaseInputType & {
    rows?:number;
};

export const InputTextArea = ({
    input,
    meta: { touched, error },
    className = "",
    label = "",
    /*onKeyPress,*/ disabled,
    placeholder = disabled?"":label,
    readOnly,
    horizontalInput,
    isRequired,
    logo,
    rows=6
}: InputTextAreaType) => {
    const { t } = useTranslation();
    const [id] = useState(("input" + input.name).replace(/[^\w]+/g,""));
    
    let ProcessedInput = (
        <Input
            type="textarea"
            {...input}
            className={ClassNames({ "has-danger": touched && error })}
            placeholder={disabled?"":t(placeholder)}
            // onKeyPress={onKeyPress}
            disabled={disabled}
            readOnly={readOnly}
            rows={rows}
            id={id}
        />
    );

    if (logo) {
        ProcessedInput = (
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <span className="input-group-text">
                        <FontAwesomeIcon icon={logo} />
                    </span>
                </InputGroupAddon>
                {ProcessedInput}
            </InputGroup>
        );
    }

    return (
        <FormGroup row={horizontalInput} className={className}>
            {label && (
                <Label for={id} /*sm={horizontalInput ? 2 : false}*/>
                    {t(label)} {isRequired ? <small>*</small> : ""}
                </Label>
            )}
            <Col sm={horizontalInput ? 10 : false} style={{ padding: "0" }}>
                {ProcessedInput}
            </Col>
            <UncontrolledTooltip
                className={ClassNames({ "force-hidden": !(touched && error) })}
                placement="right"
                target={id}
                flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
            >
                {t(error)}
            </UncontrolledTooltip>
        </FormGroup>
    );
};
