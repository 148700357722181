import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Col, Row } from "reactstrap";

import { InputText, InputPassword, InputSelect } from "../../common/component/Input";
import { withTranslation } from "react-i18next";
import {
    slcUpdateMyAccount,
    actEditMyAccount,
    actEditLanguage,
    slcCurrentLanguage,
    actFetchMyAccount,
    slcFetchMyAccount,
} from "../redux/myAccount";
import SimpleFormBox from "../../common/component/SimpleFormBox";
import SimpleBox from "../../common/component/SimpleBox";

//react-router path
// const path = "/my-account";
const formName = "myAccountForm";
//remapping retrieved value to field name

class MyAccountForm extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            disableField: false,
        };
    }

    componentDidMount() {
        this.props.actFetchMyAccount();

        if (this.props.currentLanguage == null) this.props.change("languageCode", "id");
        else this.props.change("languageCode", this.props.currentLanguage);
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.myAccount.data && prevProps.myAccount.data === null) {
            this.props.change("name", this.props.myAccount.data.name);
            this.props.change("email", this.props.myAccount.data.email);
        }

        if (this.props.currentLanguage !== prevProps.currentLanguage) {
            this.props.change("languageCode", this.props.currentLanguage);
        }
    }

    onSubmit = (values: any) => {
        let param = { ...values };
        this.props.actEditMyAccount(param);
    };

    render() {
        const { updateMyAccount, actEditLanguage, handleSubmit, match } = this.props;

        return (
            <>
                <SimpleBox title="common.applicationSetting">
                    <Row>
                        <Col>
                            <Field
                                name="languageCode"
                                label="common.language"
                                component={InputSelect}
                                options={[{ value: "en", label: "English" }, { value: "id", label: "Indonesia" }]}
                                onChange={actEditLanguage}
                            />
                        </Col>
                    </Row>
                </SimpleBox>
                <SimpleFormBox
                    title="common.myAccountForm"
                    submitting={updateMyAccount.loading}
                    onSubmit={handleSubmit(this.onSubmit)}
                    disableSubmit={false}
                    disableBack={true}
                    match={match}
                    formName={formName}
                    skipClosePrompt={true}
                >
                    <Row>
                        <Col>
                            <Field
                                name="currentPassword"
                                component={InputPassword}
                                label="common.currentPassword"
                                disabled={this.state.disableField}
                                required={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Field name="name" label="common.name" component={InputText} />
                            <Field name="email" label="common.email" component={InputText} />
                        </Col>
                        <Col>
                            <Field
                                name="password"
                                component={InputPassword}
                                label="common.newPassword"
                                disabled={this.state.disableField}
                            />
                            <Field
                                name="repeatPassword"
                                component={InputPassword}
                                label="common.repeatPassword"
                                disabled={this.state.disableField}
                            />
                        </Col>
                    </Row>
                </SimpleFormBox>
            </>
        );
    }
}

function validate(values: any, ownProps: any) {
    let errors: any = {};
    const errorMsg = "validation.pleaseEnterAValue";

    if (values.password && values.password.length < 6) errors.password = "validation.passwordMustBeAtLeast6";
    if (values.password && values.repeatPassword == null) errors.repeatPassword = errorMsg;
    if (values.password && values.repeatPassword && values.password !== values.repeatPassword) {
        errors.password = "validation.passwordDontMatch";
        errors.repeatPassword = "validation.passwordDontMatch";
    }
    if (values.currentPassword == null) errors.currentPassword = errorMsg;

    return errors;
}

function mapStateToProps(state: any) {
    return {
        updateMyAccount: slcUpdateMyAccount(state),
        myAccount: slcFetchMyAccount(state),
        currentLanguage: slcCurrentLanguage(state),
    };
}

export default connect(
    mapStateToProps,
    { actEditMyAccount, actEditLanguage, actFetchMyAccount }
)(reduxForm<any, any>({ validate, form: formName })(withTranslation()(MyAccountForm)));
