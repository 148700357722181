/**
 * Old name: actionConfig & actionSetting
 *
 * Used to get all setting in general (without param)
 *
 * Usually used as first-open call
 *
 * Reducers are finance-related setting that affect how the app handles calculation
 */

import reducerRegistry from "../../common/reduxHandler/reducerRegistry";
import { reqGet, CACHE_MODE } from "../../common/function/request";
import { setFetchAllParam } from "../../common/function/requestParamHelper";
import { createSelector } from "reselect";
import { calculateTaxRatio } from "../../common/function/showValueHelper";

//initial state
const initialState: CommonReduxState = {
    loading: false,
    data: null,
    error: null,
};

//action
const createActionName = (name: string) => `setting/${name}`;
const INITIALIZE_CONFIG_SETTING = createActionName("INITIALIZE_CONFIG_SETTING");
const INITIALIZE_CONFIG_SETTING_DONE = createActionName("INITIALIZE_CONFIG_SETTING_DONE");
const INITIALIZE_CONFIG_SETTING_FAILED = createActionName("INITIALIZE_CONFIG_SETTING_FAILED");

//reducer
function initializeConfigSetting(state = initialState, action: DispatchedAction<APIListResponse>) {
    switch (action.type) {
        case INITIALIZE_CONFIG_SETTING:
            return { ...initialState, loading: true };
        case INITIALIZE_CONFIG_SETTING_DONE:
            return {
                ...state,
                loading: false,
                data: action.payload.data.list,
            };
        case INITIALIZE_CONFIG_SETTING_FAILED:
            return { ...state, error: action.error, loading: false };
        default:
            return state;
    }
}

//register reducer
reducerRegistry.register("initializeConfigSetting", initializeConfigSetting);

//selector
export const slcInitialConfigSetting = (state: any): CommonReduxState => state["initializeConfigSetting"];

const getInitialConfigSetting = (state: any): CommonReduxState =>
    state["initializeConfigSetting"].data ? state["initializeConfigSetting"].data : [];

export const slcSettingOutputVAT_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "pajak-keluaran");
        return data ? Number(data.value) : 0;
    }
);

export const slcSettingInputVAT_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "pajak-masukan");
        return data ? Number(data.value) : 0;
    }
);

export const slcSettingOutputVAT_Ratio = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "pajak-keluaran");
        return data ? Number(calculateTaxRatio(data.value)) : 0;
    }
);

export const slcSettingInputVAT_Ratio = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "pajak-masukan");
        return data ? Number(calculateTaxRatio(data.value)) : 0;
    }
);

/**
 * Returns several tax-related keys.
 *
 * data: The tax value
 * isTaxable: PKP company
 * isNonTaxable: NON-PKP company
 */
export const slcSettingCompanyTaxStatus = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): { data: any; isTaxable: boolean; isNonTaxable: boolean } => {
        const data = initConfig.find((val: any) => val.key === "status-badan-usaha");
        return {
            data: data ? data.value : "",
            isTaxable: data ? data.value === "PKP" : false,
            isNonTaxable: data ? data.value === "NON-PKP" : false,
        };
    }
);

/**
 * Returns Array of Object containing key:string and value:any
 */
export const slcSettingCompanyInfo_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): { key: string; value: any }[] => {
        // info perusahaan config
        let infoPerusahaan: any = [];
        for (let i = 0; i < initConfig.length; i++) {
            const row = initConfig[i];
            if (
                row.key === "nama-perusahaan" ||
                row.key === "bidang-usaha" ||
                row.key === "alamat-perusahaan" ||
                row.key === "kecamatan" ||
                row.key === "kelurahan" ||
                row.key === "kota" ||
                row.key === "provinsi" ||
                row.key === "kode-pos" ||
                row.key === "negara" ||
                row.key === "no-telepon" ||
                row.key === "email" ||
                row.key === "status-badan-usaha" ||
                row.key === "no-npwp"
            )
                infoPerusahaan.push(row);
        }

        return infoPerusahaan;
    }
);

/**
 * Check whether this app may use inclusive tax or not
 */
export const slcSettingInclusiveTax_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-inclusive-tax");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Check whether money calculations require rounding or not
 */
export const slcSettingRoundFeature_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-pembulatan");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Allow/Disallow duplciated product-id in DynamicTables
 */
export const slcSettingAllowDuplicateProduct_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-produk-kembar-dalam-satu-nota");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

export const slcSettingIsInstalled_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fase-instalasi");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

export const slcSettingStepInstallation_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number | null => {
        const data = initConfig.find((val: any) => val.key === "step-instalasi-terakhir");
        return data ? parseInt(data.value) : null;
    }
);

/**
 * Check whether SO/Sales price modifiable or not
 */
export const slcSettingInventoryTypeAVG = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "sistem-persediaan");
        return data.value === "AVG" ? true : false;
    }
);

/**
 * Check whether SO/Sales price modifiable or not
 */
export const slcSettingInventoryTypeFIFO = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "sistem-persediaan");
        return data.value === "FIFO" ? true : false;
    }
);

/**
 * Check whether SO/Sales price modifiable or not
 */
export const slcSettingFixSalePrice_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-kunci-harga-jual");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Get default sales-deposit coa
 */
export const slcSettingDefaultSalesDepositCoa = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "default-coa-uang-muka-penjualan");
        return data ? Number(data.value) : 62;
    }
);

/**
 * Get default saldo awal ekuitas coa
 */
export const slcSettingDefaultBeginningBalanceCoa = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "default-coa-saldo-awal-ekuitas");
        return data ? Number(data.value) : 86;
    }
);

/**
 * Get default purchase-deposit coa
 */
export const slcSettingDefaultPurchaseDepositCoa = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): number => {
        const data = initConfig.find((val: any) => val.key === "default-coa-uang-muka-pembelian");
        return data ? Number(data.value) : 25;
    }
);

/**
 * Allow change product-name in Sales/SJ or not
 */
export const slcSettingCustomProductName_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-custom-product-name");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Allow change product-name in Sales/SJ or not
 */
export const slcSettingCheckCustomerLimit = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "batasi-penjualan-berdasarkan-limit");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Check to show (true) / hide (false) Dot-Matrix Print Menu
 */
export const slcSettingTurnOnDotMatrixPrint_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-print-menggunakan-dot-matrix");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Check to change harga satuan behaviour
 */
export const slcSettingKonversiSatuanDanHargaSatuan_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-konversi-satuan-dan-harga-satuan");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Check whether this Dot-Matrix print used as default or not (if not, set HTML as default)
 *
 * Determine which print triggered in Action's DropdownButton
 */
export const slcSettingUseDotMatrixAsDefault_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "set-dot-matrix-sebagai-print-default");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Check whether this Dot-Matrix print used as default or not (if not, set HTML as default)
 *
 * Determine which print triggered in Action's DropdownButton
 */
export const slcSettingShowDecimalFeature_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-tampilkan-angka-dibelakang-koma");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

export const slcSettingFeaturePrintPaperType_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-cetak-opsi-ukuran-kertas");
        return data ? data.value : "A4 portrait";
    }
);

export const slcSettingFeaturePrintTemplate_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-cetak-template-nota");
        return data ? data.value : "STANDARD";
    }
);

export const slcSettingFeaturePrintFontType_Value = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-cetak-font-family");
        return data ? data.value : "Arial 'sans-serif'";
    }
);

/**
 * Check toast duration
 */
export const slcSettingToastDuration = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "toast-duration");
        return data;
    }
);

/**
 * Webview turned on or not
 */
export const slcSettingWebviewFeature = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "fitur-webview");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

/**
 * Webview turned on or not
 */
export const slcSettingExpandSubrow = createSelector(
    getInitialConfigSetting, //basic selector
    (initConfig): boolean => {
        const data = initConfig.find((val: any) => val.key === "default-expand-subrow");
        return data ? data.value === "1" || data.value === 1 || data.value === true : false;
    }
);

//action creator
const urlPath = "setting";

/**
 * Init config that's called on first App page load
 */
export function actInitConfig() {
    const request = reqGet(urlPath, setFetchAllParam(), null, CACHE_MODE.WITH_LOAD_WITH_SAVE, 86400); //setting rarely changed

    return (dispatch: any) => {
        dispatch({
            type: INITIALIZE_CONFIG_SETTING,
        });

        request
            .then((payload) => {
                dispatch({
                    type: INITIALIZE_CONFIG_SETTING_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: INITIALIZE_CONFIG_SETTING_FAILED,
                    error,
                });
            });
    };
}

/**
 * Reinit config. used when there's any change to config setting. Must not use cache for loading
 */
export function actReloadInitConfig() {
    const request = reqGet(urlPath, setFetchAllParam(), null, CACHE_MODE.NO_LOAD_WITH_SAVE, 86400);

    return (dispatch: any) => {
        dispatch({
            type: INITIALIZE_CONFIG_SETTING,
        });

        request
            .then((payload) => {
                dispatch({
                    type: INITIALIZE_CONFIG_SETTING_DONE,
                    payload: payload.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: INITIALIZE_CONFIG_SETTING_FAILED,
                    error,
                });
            });
    };
}

/**
 * Update 20190807. Isn't all setting already retrieved above? Just use them.
 *
 * Fetch Default COA saved inside Setting.
 * e.g. default customer deposit COA
 */
// export function actFetchSettingDefaultCoa(inputs:any = {}) {
//     const param = {
//         q: "1" + (inputs.q ? "," + inputs.q : ""),
//         searchKey: "categoryId" + (inputs.searchKey ? "," + inputs.searchKey : ""),
//         operator: "=" + (inputs.operator ? "," + inputs.operator : ""),
//         mode: "and",
//         all: true
//     };

//     const request = reqGet(urlPath, param, null, CACHE_MODE.WITH_LOAD_WITH_SAVE, 86400); //setting rarely changed

//     return (dispatch: any) => {
//         dispatch({
//             type: FETCH_SETTING_DEFAULT_COA,
//         });

//         request
//             .then((payload) => {
//                 dispatch({
//                     type: FETCH_SETTING_DEFAULT_COA_DONE,
//                     payload: payload.data,
//                 });
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: FETCH_SETTING_DEFAULT_COA_FAILED,
//                     error,
//                 });
//             });
//     };
// }
