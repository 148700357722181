import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import PrintArea from "../../../common/component/PrintArea";
import PrintHeader from "../../../common/component/PrintHeader";
import { showCurrency, showQty } from "../../../common/function/showValueHelper";
import SafeBig from "../../../common/function/safeBig";

type DeliveryReportPrintState = {
    data: any;
    companyInfo: any;
};

class DeliveryReportPrint extends PureComponent<WithTranslation, DeliveryReportPrintState> {
    constructor(props: any) {
        super(props);

        //load data from localStorage
        const stringData = localStorage.getItem("deliveryReport-print");
        const jsonData = stringData != null ? JSON.parse(stringData) : null;

        // remove data from localStorage
        localStorage.removeItem("deliveryreport-print");

        // console.log(jsonData);

        this.state = {
            data: jsonData && jsonData.data ? jsonData.data : null,
            companyInfo: jsonData && jsonData.companyInfo ? jsonData.companyInfo : null,
        };
    }

    render() {
        const { t } = this.props;
        const { companyInfo, data } = this.state;
        if (data == null) return <p>No Data</p>;
        let totalQty = SafeBig(0);
        let columns = data.detailList.map((obj: any, index: number) => {
            totalQty = totalQty.plus(SafeBig(obj.qty ?? 0));
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.productName ?? obj.product.name}</td>
                    <td className="qty">
                        {obj.unitConversion === null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " " + obj.unit?.name}</td>
                                        <td>@{ showQty(obj.unitConversion) + " " + obj.product?.unit?.name } </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                </tr>
            );
        });

        return (
            <PrintArea>
                <Table className="ra-table-print">
                    <PrintHeader
                        title={t("common.deliveryReport")}
                        companyInfo={companyInfo}
                        customer={data.customer}
                        no={data.key}
                        dtCreated={data.dtCreated}
                    />
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <Table className="ra-table-detail-print">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t("common.product")}</th>
                                            <th>{t("common.qty")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {columns}
                                        <tr>
                                            <td colSpan={2} style={{ textAlign: "right" }}>{t("common.totalQty")}</td>
                                            <td>{showCurrency(totalQty.toString(), 2)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div>
                                    {t("common.notes")}: {data.description}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </PrintArea>
        );
    }
}

export default withTranslation()(DeliveryReportPrint);
