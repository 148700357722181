import React, { Component, useState } from "react";
// import { components } from "react-select";
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
// import CreatableSelect from "react-select/lib/Creatable";
import { withTranslation, WithTranslation, useTranslation } from "react-i18next";
import { UncontrolledTooltip, FormGroup, Label, Col } from "reactstrap";
// import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from "react-virtualized";
import ClassNames from "classnames";
import { Field } from "redux-form";
import { limitedFilter } from "../../function/generator";

type InputSelectType = BaseInputType & {
    options: any[];
    multi?: boolean;
    isClearable?: boolean;
    showAllOptions?: boolean;
    onUserChange?: Function;
} & WithTranslation;

class BaseInputSelect extends Component<InputSelectType, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            id: ("input" + props.input.name).replace(/[^\w]+/g, ""),
        };
    }

    /**
     * Helps solve lagging 'a bit'
     */
    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (
            (this.props.input.value === nextProps.input.value &&
            this.props.input.meta !== nextProps.input.meta)
        ) {
            return false;
        }

        return true;
    }

    maxOptionCount = 15;
    /**
     * Lazily load options with max number of result returned
     */
    promiseOptions = async (inputValue:any) => {
        return Array.from(limitedFilter(this.props.options, (i:any)=>i.label.toLowerCase().includes(inputValue.toLowerCase()), this.maxOptionCount));
    }

    noOptionsMessage = ({inputValue}:any)=>{
        if (this.props.options==null || this.props.options.length===0){
            return this.props.t("common.noData");
        }

        if (inputValue.trim()===""){
            return this.props.t("common.typeToSearch");
        }

        return this.props.t("common.noItemFound");
    }

    render() {
        const {
            input,
            meta: { touched, error },
            label = "",
            options,
            multi = false,
            className = "",
            disabled,
            isClearable,
            isRequired,
            placeholder = label,
            horizontalInput,
            showAllOptions,
            onUserChange,
            t
        } = this.props;

        const { name, value, onChange, onFocus} = input;
        const {id} = this.state;
        const transformedValue = transformValue(value, options, multi);

        return (
            <FormGroup row={horizontalInput} className={className}>
                {label && (
                    <Label for={id} /*sm={horizontalInput ? 2 : false}*/>
                        {t(label)} {isRequired ? <small>*</small> : ""}
                    </Label>
                )}
                <Col sm={horizontalInput ? 10 : false} style={{ padding: "0" }}>
                    <AsyncSelect
                        {...input}
                        // components={{ MenuList: MenuList }} //No longer needed as options are limited in size (lazy load)
                        // key="input"
                        id={id}
                        isDisabled={disabled ? disabled : false}
                        isClearable={isClearable}
                        // valueKey="value"
                        name={name}
                        value={transformedValue}
                        isMulti={multi}
                        // options={options}
                        defaultOptions={showAllOptions || options.length<=this.maxOptionCount?options:[]}
                        loadOptions={this.promiseOptions}
                        noOptionsMessage={this.noOptionsMessage}
                        onChange={multi ? multiChangeHandler(onChange, onUserChange) : singleChangeHandler(onChange, onUserChange)}
                        // onBlur={() => onBlur(value)}
                        onBlur={event => event.preventDefault()} //hotfix unable to select on mobile phones
                        onFocus={onFocus}
                        placeholder={disabled?"":t(placeholder)}
                        className={ClassNames(className, "select-hotfix", {
                            "select-has-danger": touched && error,
                            "select-disabled": disabled
                        })}
                        //portal setting
                        menuPortalTarget={document.body}
                        menuPlacement={"auto"}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </Col>
                <UncontrolledTooltip
                    className={ClassNames({ "force-hidden": !(touched && error) })}
                    placement="right"
                    target={id}
                    flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
                >
                    {t(error)}
                </UncontrolledTooltip>
            </FormGroup>
        );
    }
}

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func: Function, onUserChange?:Function) {
    return function handleSingleChange(value: any) {
        func(value ? value.value : "");

        if (onUserChange!=null){
            onUserChange(value ? value.value : "");
        }
    };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func: Function, onUserChange?:Function, isCreatable = false) {
    return function handleMultiHandler(values: any) {
        func(values?.map((value: any) => value.value));

        if (onUserChange!=null)
            onUserChange(values?.map((value: any) => value.value));
    };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value: any, options: any, multi: boolean, isCreatable = false) {
    if (multi && typeof value === "string") return []; //just to avoid error
    if (multi && value.length === 0) return null; //to avoid blank box
    if (value==="" || value===null) return null;
    if ((options==null || options.length===0) && !isCreatable) return null;

    //try to find selected value in option list
    let usedValue:string[] = [];
    const filteredOptions = options.filter((option: any) => {
        let found = multi ? value.indexOf(option.value) !== -1 : option.value === value;

        if (found){
            usedValue.push(option.value);
            return true;
        }
        return false;
    });

    //if creatable, allow create new (will not create new if the value is found above)
    if (isCreatable /*&& (filteredOptions==null || filteredOptions.length===0)*/ && value != null) {
        
        //to allow created-data
        if (!multi) {
            //not multi, so prefer to use filteredOption if available
            if (value.toString().trim() !== ""&& (filteredOptions==null || filteredOptions.length===0)) {
                //if value is not empty
                return { value, label: value }; //return an object as value
            }
        } else {
            //allow multi, allow combination with filteredOptions
            //value for multi is an array
            let newOptions = value.flatMap((val: any) => {
                if (usedValue.includes(val)) return [];

                return { value: val, label: val };
            }); //so return an array of objects

            return [...newOptions, ...filteredOptions];
        }
    }

    return multi ? filteredOptions : filteredOptions[0]; //return found option
}

/**
 * TransformedValue is only used to help show the data in the Component (not always the real data)
 *
 * Problem:
 * - Value cannot be Number
 * - Created-label (new) are set as value by the singleInputChangeHandler, not the transformValue
 */
export const InputCreatableSelect = React.memo(
    ({
        input,
        meta: { touched, error },
        label = "",
        options,
        multi = false,
        className = "",
        disabled,
        isClearable,
        isRequired,
        horizontalInput,
        onUserChange,
        placeholder = label
    }: InputSelectType) => {
        const { name, value, onChange, onFocus/*, onBlur */} = input;
        const transformedValue = transformValue(value, options, multi, true);
        
        const { t } = useTranslation();
        const [id] = useState(("input" + input.name).replace(/[^\w]+/g,""));
        return (
            <FormGroup row={horizontalInput} className={className}>
                {label && (
                    <Label for={id} /*sm={horizontalInput ? 2 : false}*/>
                        {t(label)} {isRequired ? <small>*</small> : ""}
                    </Label>
                )}
                <Col sm={horizontalInput ? 10 : false} style={{ padding: "0" }}>
                    <CreatableSelect
                        {...input}
                        key="input"
                        id={id}
                        isDisabled={disabled ? disabled : false}
                        // valueKey="value"
                        name={name}
                        value={transformedValue}
                        isMulti={multi}
                        isClearable={isClearable}
                        options={options}
                        placeholder={disabled?"":t(placeholder)}
                        onChange={multi ? multiChangeHandler(onChange, onUserChange, true) : singleChangeHandler(onChange, onUserChange)}
                        // onBlur={() => onBlur(value)}
                        onBlur={event => event.preventDefault()} //hotfix unable to select on mobile phones
                        onFocus={onFocus}
                        className={ClassNames(className, "select-creatable", "select-hotfix", {
                            "select-has-danger": touched && error,
                            "select-disabled": disabled
                        })}
                        //portal setting
                        menuPortalTarget={document.body}
                        menuPlacement={"auto"}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </Col>
                <UncontrolledTooltip
                    className={ClassNames({ "force-hidden": !(touched && error) })}
                    placement="right"
                    target={id}
                    flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
                >
                    {t(error)}
                </UncontrolledTooltip>
            </FormGroup>
        );
    }
);

export const InputSelect = withTranslation()(BaseInputSelect);

export class ConnectedInputSelect extends Component<any, any> {
    onChange = (val:any)=>{
        if (this.props.onChange!=null)
            this.props.onChange(this.props.index, val);
    }
    render() {
        const {
            name,
            disabled,
            options,
            ...props
        } = this.props;

        return (
            <Field
                {...props}
                name={name}
                component={InputSelect}
                disabled={disabled}
                options={options}
                onChange={this.onChange}
            />
        );
    }
}