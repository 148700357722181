import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Redirect } from "react-router-dom";
import { Form, Button, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

import { InputText, InputPassword, InputCheck } from "../../common/component/Input";
import LogoLong from "../../../asset/img/logo-full-2.png";

import { actUserLogin, slcUserLogin, slcLoginRedirectURL } from "../redux/login";

// type LoginPageType = {};

class LoginPage extends PureComponent<any, any> {
    recaptchaRef:any;
    constructor(props: any) {
        super(props);

        this.state = {
            captcha: process.env.REACT_APP_USE_RECAPTCHA === "true" ? false : true, //if not using recaptcha, allow immediately
            recaptchaToken: null,
        };
    }
    onSubmit = (values: any) => {
        if (values.rememberMe === true) {
            localStorage.setItem("loginUsername", values.username);
        } else {
            localStorage.removeItem("loginUsername");
        }
        this.props.actUserLogin(values.username, values.password, this.state.recaptchaToken);
    };

    /**
     * Called on success
     */
    onRecaptcha = (value: any) => {
        if (value == null) {
            //value is null if expired
            this.setState({ captcha: false, recaptchaToken: null });
        } else {
            this.setState({ captcha: true, recaptchaToken: value });
        }
    };

    componentDidMount() {
        document.body.style.backgroundColor = "rgb(40,50,80)";
        if (this.props.isLoggedIn) {
            this.props.history.push("/");
        }
    }

    componentDidUpdate(prevProps:any) {
        if (this.props.isLoggedIn) {
            this.props.history.push("/");
        }

        if (this.props.userLogin !== prevProps.userLogin && this.props.userLogin.error!=null){
            if (this.recaptchaRef!=null)
                this.recaptchaRef.reset();
            this.setState({ captcha: process.env.REACT_APP_USE_RECAPTCHA === "true" ? false : true, recaptchaToken: null });
        }
    }

    render() {
        const { t, redirectURL, userLogin, handleSubmit } = this.props;
        let redirectComponent = null;
        if (userLogin.isLoggedIn === true) {
            redirectComponent = <Redirect to={redirectURL} />;
        }

        return (
            <div className="login-box">
                <Helmet>
                    <title>Login - Webview</title>
                </Helmet>
                {redirectComponent}
                <div className="login-logo">
                    <span className="logo-lg">
                        <img src={LogoLong} height="100px" alt="logo" />
                    </span>
                </div>
                <div className="login-box-body">
                    <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Field
                            name="username"
                            placeholder="common.username"
                            component={InputText}
                            logo="user"
                        />
                        <Field
                            name="password"
                            placeholder="common.password"
                            component={InputPassword}
                            logo="lock"
                        />
                        {userLogin.error != null ? (
                            <p style={{ color: "red" }}>
                                {t("validation.wrongUserPass")}
                                <br />
                            </p>
                        ) : (
                            <span />
                        )}
                        {process.env.REACT_APP_USE_RECAPTCHA === "true" && (
                            <Row style={{ marginBottom: "10px" }}>
                                <Col>
                                    <ReCAPTCHA
                                        ref={(ref)=>this.recaptchaRef=ref}
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? ""}
                                        onChange={this.onRecaptcha}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col xs="9">
                                <Field name="rememberMe" label="common.rememberMe" component={InputCheck} />
                            </Col>
                            <Col xs="3">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="float-right"
                                    onClick={handleSubmit(this.onSubmit)}
                                    disabled={!this.state.captcha}
                                >
                                    {t("common.signIn")}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        );
    }
}

function validate(values: any) {
    let errors: any = {};
    if (values.username == null) {
        errors.username = "validation.username";
    }
    if (values.password == null) {
        errors.password = "validation.password";
    }
    return errors;
}

function mapStateToProps(state: any) {
    const savedUsername = localStorage.getItem("loginUsername");
    return {
        userLogin: slcUserLogin(state),
        redirectURL: slcLoginRedirectURL(state),
        initialValues: { username: savedUsername ? savedUsername : "", rememberMe: savedUsername != null },
    };
}

export default connect(
    mapStateToProps,
    { actUserLogin }
)(
    reduxForm<any, any>({
        validate,
        form: "appLoginForm",
    })(withTranslation()(LoginPage))
);
