import dayjs from "dayjs";
const cacheDuration = 86400; //1 day

type CachedData = {
    exp: string; //expiry time of the cache
    param: any; //the request parameter that returns the data
    data: any; //response data from the request
};

type CachedDataCollection = CachedData[];

type SingleCache = {
    exp: string; //expiry time of the cache
    data: any; //single data (any allowed)
};

// /**
//  * Save data into cache. Only usable for ONE data.
//  * Currently used for Get-All (select-options data)
//  *
//  * @param key
//  * @param data Any JSON data
//  */
// export function saveSingleCache(key:string, data:any){
//     const toBeSaved:CachedData = {
//         exp: dayjs().add(cacheDuration, "s").toISOString(),
//         data
//     }

//     const jsonString = JSON.stringify(toBeSaved);

//     localStorage.setItem(key, jsonString);
// }

// /**
//  * Get cache data by key if any.
//  * Returns null if nonefound
//  * @param key
//  */
// export function getSingleCacheByKey(key:string){
//     const savedData = localStorage.getItem(key);
//     if (savedData==null) return null;

//     try {
//         const parsedData:CachedData = JSON.parse(savedData);
//         if (dayjs(parsedData.exp).isBefore(dayjs())){ //if already expired
//             deleteCache(key);
//             return null;
//         }

//         return parsedData.data;
//     } catch (err){
//         return null;
//     }
// }

/**
 * Force delete all cache with identifier "key".
 *
 * Need to be called to "Reset" cache for a certain key.
 *
 * E.g. Resetting Master-Product cache after Create/Update/Delete.
 *
 * @param key Cache key (URL)
 */
export function deleteCacheCollection(key: string) {
    //special for option-url (because of the route change)
    if (localStorage.getItem(`${key}/option`) != null) localStorage.removeItem(`${key}/option`);
    else if (localStorage.getItem(`@admin/${key}/option`) != null) localStorage.removeItem(`@admin/${key}/option`);
    else localStorage.removeItem(key);
}

//don't forget to delete some of these keys in login.ts/actResetUserLogin
const importantCacheKey = ["access", "hpT", "loginUsername", "language", "newNotification"];

/**
 * Clear all non-important cache
 */
export function clearAllCache() {
    let keyToRemove = [];
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key != null && !importantCacheKey.includes(key)) keyToRemove.push(key);
    }
    //because any removal is direct removal (and the index are moved)
    for (let i = 0; i < keyToRemove.length; i++) {
        deleteCacheCollection(keyToRemove[i]);
    }
}

/**
 * Try to find FIRST data inside collection that have exact same parameter.
 *
 * Example CachedData: {exp:x, data:y, param:{all:true}}
 *
 * @param collection Cached Collection of data
 * @param param Request parameter
 */
function findDataInCollectionByParam(collection: CachedDataCollection, param: { [key: string]: any }) {
    for (let i = 0; i < collection.length; i++) {
        const val = collection[i];
        if (val.data == null) continue; //if no data, skip
        if (
            param !== null &&
            Object.keys(param).length !== 0 &&
            Object.keys(val.param).length !== Object.keys(param).length
        )
            continue; //if keylen different, skip
        let match = true;
        for (let key in param) {
            //matching all keys
            if (val.param[key] !== param[key]) {
                //if any key don't match, skip
                match = false;
                continue;
            }
        }

        if (match === true) return { foundData: val, index: i }; //we found the data
    }

    return null;
}

/**
 * Delete a data from a collection with identifier "key"
 *
 * @param key Cache key (URL)
 * @param index Deleted index
 */
export function deleteDataFromCollection(key: string, index: number) {
    let collection: CachedDataCollection = [];

    const savedData = localStorage.getItem(key);
    if (savedData != null) {
        //if there's any existing cache
        try {
            collection = JSON.parse(savedData);
            collection.splice(index, 1);

            const jsonString = JSON.stringify(collection);
            localStorage.setItem(key, jsonString);
        } catch (err) {}
    }
}

/**
 * Save a data into a collection.
 *
 * Will create a new data collection if none found, or update if one already found
 *
 * @param key Cache key (URL)
 * @param param Request parameter/data
 * @param data Response data
 * @param customDuration Cache validity duration
 */
export function saveIntoCacheCollection(key: string, param: any, data: any, customDuration = cacheDuration) {
    let collection: CachedDataCollection = [];

    const savedData = localStorage.getItem(key);
    if (savedData != null) {
        //if there's any existing cache
        try {
            collection = JSON.parse(savedData);
        } catch (err) {}
    }

    //find any data with same param
    const previousData = findDataInCollectionByParam(collection, param);

    //add new data into collection
    let newData: CachedData = {
        exp: dayjs()
            .add(customDuration, "s")
            .toISOString(),
        param,
        data,
    };

    if (previousData != null) {
        collection.splice(previousData.index, 1, newData);
    } else {
        collection.push(newData);
    }

    //save
    try {
        const jsonString = JSON.stringify(collection);
        localStorage.setItem(key, jsonString);
    } catch {
        console.log("Can't add cache");
    }
}

/**
 * Get cache data by key+param if any.
 * Returns null if nonefound
 * @param key Cache key (URL)
 * @param param Request parameter/data
 */
export function getDataFromCachedCollection(key: string, param: { [key: string]: any }) {
    let collection: CachedDataCollection = [];

    const savedData = localStorage.getItem(key);
    if (savedData != null) {
        //if there's any existing cache
        try {
            collection = JSON.parse(savedData);
            const cachedData = findDataInCollectionByParam(collection, param);

            if (cachedData != null) {
                //if found, check exp time
                if (dayjs(cachedData.foundData.exp).isBefore(dayjs())) {
                    //if already expired
                    deleteDataFromCollection(key, cachedData.index);
                    return null;
                }

                return cachedData.foundData.data;
            }
        } catch (err) {}
    }

    return null;
}

/**
 * Save a data into a single cache key.
 *
 * Will create/replace localStorage key immediately
 *
 * @param key Cache key
 * @param data Response data
 * @param customDuration Cache validity duration
 */
export function saveIntoSingleCache(key: string, data: any, customDuration = cacheDuration) {
    //add new data into collection
    let newData: SingleCache = {
        exp: dayjs()
            .add(customDuration, "s")
            .toISOString(),
        data,
    };

    //save
    try {
        const jsonString = JSON.stringify(newData);
        localStorage.setItem(key, jsonString);
    } catch {
        console.log("Can't add cache");
    }
}

/**
 * Get cache data by key (in regard of expiry time)
 * Returns null if nonefound
 *
 * @param key Cache key
 */
export function getDataFromSingleCache(key: string) {
    const savedData = localStorage.getItem(key);
    if (savedData != null) {
        //if there's any existing cache
        try {
            let parsedData: SingleCache = JSON.parse(savedData);

            if (dayjs(parsedData.exp).isBefore(dayjs())) {
                //if already expired
                localStorage.removeItem(key);
                return null;
            }

            return parsedData.data;
        } catch (err) {}
    }

    return null;
}
