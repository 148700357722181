import React from "react";
import { useTranslation } from "react-i18next";
import changeCase from "change-case";
import { showDate } from "../../function/showValueHelper";

type PrintHeaderProps = {
    title: string;
    companyInfo: any;
    supplier?: any;
    customer?: any;
    no: string;
    dtCreated: string;
};

function getData(companyInfo: any, key: string) {
    let obj = companyInfo.find((val: any) => val.key === key);
    return obj ? obj.value : "";
}

const SimpleHeader = React.memo(({ title, companyInfo, supplier, customer, no, dtCreated }: PrintHeaderProps) => {
    const { t } = useTranslation();
    let to = supplier != null ? supplier : customer;

    return (
        <thead>
            <tr>
                <th colSpan={2} id="docTitle">
                    {changeCase.upper(title)}
                </th>
            </tr>
            <tr style={{ marginBottom: "10px" }}>
                <th style={{ width: "60%" }}>
                    <b>{no}</b>
                </th>
                <th>
                    <b>
                        {t("common.createDate")}: {showDate(dtCreated)}
                    </b>
                </th>
            </tr>
            <tr>
                <th>
                    {getData(companyInfo, "nama-perusahaan")} <br />
                    {getData(companyInfo, "alamat-perusahaan")}
                    {getData(companyInfo, "kota") ? ", " + getData(companyInfo, "kota") + "." : ""}
                </th>
                <th>
                    {t("common.recipient")}: <br />
                    {to.name} <br />
                    {to.address}
                    {to.city ? ", " + to.city + "." : ""}
                </th>
            </tr>
        </thead>
    );
});

export default SimpleHeader;
