import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router";
import tutorialConfig from "../../../../tutorial/tutorialConfig";

type TutorialModalState = {
    isOpen: boolean;
    openedDefinition: TutorialDefinition | null;
};

class TutorialModal extends Component<any, TutorialModalState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
            openedDefinition: null,
        };
    }

    componentDidMount() {
        // console.log(this.props.location);
        this.parsePath(this.props.location.pathname);
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.parsePath(this.props.location.pathname);
            // console.log(this.props.location);
        }
    }

    parsePath = (path: string) => {
        path = path.trim().toLowerCase();
        let split = path.split("/");

        let module = split[1];
        let type = "list";
        if (split.length > 2) {
            if (split[2] === "edit" || split[2] === "show" || split[2] === "new") {
                type = "form";
            }
        }

        // console.log(`${module}/${type}`);

        this.setState({
            openedDefinition: tutorialConfig[`${module}/${type}`],
        });
    };

    onToggleModal = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    renderBody = () => {
        const { openedDefinition, } = this.state;
        const { t } = this.props;

        if (openedDefinition == null) return <p>{t("common.noTutorial")}</p>;

        return (
            <div>
                <iframe title={openedDefinition.title} src={openedDefinition.url} height={window.innerHeight-230} width="100%"/>
            </div>
        );
    };

    render() {
        const { isOpen, openedDefinition } = this.state;
        const { t } = this.props;

        if (openedDefinition == null) return <div />;

        return (
            <>
                <Button onClick={this.onToggleModal} color="default">
                    <FontAwesomeIcon icon="book" style={{ marginRight: "8px" }} />
                    {t("common.tutorial")}
                </Button>
                <Modal isOpen={isOpen} toggle={this.onToggleModal} className="popup-modal" size="lg">
                    <ModalHeader>{`${t("common.tutorial")}: ${t(openedDefinition.title)}`}</ModalHeader>
                    <ModalBody>{this.renderBody()}</ModalBody>
                    <ModalFooter className="justify-content-between">
                        <Button color="default" onClick={this.onToggleModal}>
                            <FontAwesomeIcon icon="caret-left" style={{ marginRight: "8px" }} />
                            {t("common.close")}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

//@ts-ignore
export default withTranslation()(withRouter(TutorialModal));
