import React, { PureComponent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table } from "reactstrap";
import SimpleHeader from "../../../common/component/PrintHeader/SimpleHeader";
import { showCurrency, showQty } from "../../../common/function/showValueHelper";

type InjectedProps = {
    data: any;
    companyInfo: any;
};

class Simple extends PureComponent<WithTranslation & InjectedProps> {
    render() {
        const { t, data, companyInfo } = this.props;
        if (data == null) return <p>No Data</p>;
        let columns = data.detailList.map((obj: any, index: number) => {
            return (
                <tr key={"detail-" + index}>
                    <td className="rowNo">{index + 1}</td>
                    <td>{obj.productName ?? obj.product.name}</td>
                    <td className="qty">
                        {obj.unitConversion === null ? (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " "}</td>
                                        <td>{obj.product?.unit?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <table className="printQtyTable">
                                <tbody>
                                    <tr key={"detail-1-" + index}>
                                        <td>{showQty(obj.qty) + " " + obj.unit?.name}</td>
                                        <td>@{ showQty(obj.unitConversion) + " " + obj.product?.unit?.name } </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                    <td className="price">{showCurrency(obj.price, 2)}</td>
                    <td className="disc">{showCurrency(obj.disc1, 2)}</td>
                    <td className="subTotal">{showCurrency(obj.subtotal, 2)}</td>
                </tr>
            );
        });

        let badanUsaha = companyInfo.find((val: any) => {
            return val.key === "status-badan-usaha";
        });

        return (
            <Table className="ra-table-print">
                <SimpleHeader
                    title={t("common.sales")}
                    companyInfo={companyInfo}
                    customer={data.customer}
                    no={data.key}
                    dtCreated={data.dtCreated}
                />
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <Table className="ra-table-detail-print">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{t("common.product")}</th>
                                        <th>{t("common.qty")}</th>
                                        <th>{t("common.price")}</th>
                                        <th>{t("common.discount")}</th>
                                        <th>{t("common.subTotal")}</th>
                                    </tr>
                                </thead>
                                <tbody>{columns}</tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={4}>
                                            <b>{t("common.calculatedPrice")}: </b> <br /> {data.say} {" "} Rupiah
                                        </td>
                                        <td>{t("common.discount")}</td>
                                        <td>{showCurrency(data.disc2AmountTotal)}</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={5} colSpan={4}>
                                            {t("common.notes")}: {data.description}
                                        </td>
                                    </tr>
                                    {badanUsaha === "PKP" ? (
                                        <tr>
                                            <td>{t("common.taxBase")}</td>
                                            <td>{showCurrency(data.subtotal, 2)}</td>
                                        </tr>
                                    ) : null}
                                    {badanUsaha === "PKP" ? (
                                        <tr>
                                            <td>{t("common.tax")}</td>
                                            <td>{showCurrency(data.vat, 2)}</td>
                                        </tr>
                                    ) : null}
                                    {data.dpUsage ? (
                                        <tr>
                                            <td>{t("common.downPayment")}</td>
                                            <td>{showCurrency(data.dpUsage, 2)}</td>
                                        </tr>
                                    ) : null}
                                    {data.totalReceived ? (
                                        <tr>
                                            <td>{t("common.payment")}</td>
                                            <td>{showCurrency(data.totalReceived, 2)}</td>
                                        </tr>
                                    ) : null}
                                    <tr>
                                        <td>{t("common.grandTotal")}</td>
                                        <td>{showCurrency(data.grandTotal, 2)}</td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
}

export default withTranslation()(Simple);
