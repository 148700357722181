import changeCase from "change-case";
import cloneDeep from "clone-deep";
import React, { PureComponent, Suspense } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { boolAuthChecker } from "../../common/component/AuthCheck";
import { trackPage } from "../../common/function/googleAnalytics";
import Modules from "../../moduleConfig";
import { actInitConfig } from "../../Setting/redux/setting";
import GlobalAPINotification from "../component/GlobalAPINotification";
import MainNavbar from "../component/MainNavbar";
import Sidebar from "../component/Sidebar";
import { actSetRedirectURL, slcUserLogin } from "../redux/login";
import { actFetchMyAccount, slcFetchMyAccount } from "../redux/myAccount";
import MyAccountPage from "./MyAccountPage";

type EnsureLoginPageState = {
    isSidebarOpen: boolean;
    backgroundColor: string;
    activeColor: string;
    routeList: any[];
    sidebarLink: any[];
    helmet: JSX.Element | null;
};

/**
 * Page for logged-in user.
 * Need to check first-mount access eligibility.
 */
class EnsureLoginPage extends PureComponent<any, EnsureLoginPageState> {
    listener: any = null; //react router listener
    constructor(props: any) {
        super(props);

        //load all modules defined inside ModuleConfig
        let routeList: any = []; //put all components i
        let moduleList = cloneDeep(Modules); //clone
        let index = 0;
        while (moduleList.length > 0) {
            //dfs
            let currentModule = moduleList[0];
            moduleList.shift();

            const TargetPage = currentModule.component;
            let allowed = true;
            if (currentModule.authorityModule) {
                if (
                    //allowed
                    boolAuthChecker(
                        currentModule.authorityModule,
                        currentModule.authorityType != null ? currentModule.authorityType : [],
                        currentModule.authorityType == null ? true : false
                    )
                ) {
                    allowed = true;
                }
            } else {
                allowed = true;
            }

            if (allowed) {
                if (currentModule.children != null) {
                    //not leaf node, push them but don't create new route
                    moduleList.push(...currentModule.children);
                } else {
                    //leaf node, add a Route for it so it will be accessible
                    routeList.push(<Route key={index++} path={currentModule.path} component={TargetPage} />);
                }
            }
        }
        const sidebarState = localStorage.getItem("sidebarState");
        this.state = {
            isSidebarOpen: sidebarState === "true" ? true : false,
            backgroundColor: "black",
            activeColor: "info",
            routeList,
            sidebarLink: Modules, //pass Modules to be processed by sidebar to create Links
            helmet: null,
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#f4f6f9";
        document.body.classList.add("sidebar-mini");
        document.body.classList.add("bg-adminlte-gray");

        const sidebarState = localStorage.getItem("sidebarState");
        if (sidebarState === "true") {
            document.body.classList.add("sidebar-open");
        } else {
            document.body.classList.add("sidebar-collapse");
        }

        if (this.props.userLogin.isLoggedIn === false) {
            this.props.actSetRedirectURL(this.props.location.pathname);
        } else {
            //We can initialize here (not outside if to avoid duplicated call)
            //happens on app-loginpage-app -> twice app didMount

            this.props.actInitConfig();
            // fetch account

            this.props.actFetchMyAccount();

            // no constraint for refine-webview
            // if (process.env.REACT_APP_IS_INVENTORY !== "true") {
            //     this.props.actFetchPeriodDateConstraint();
            // }

            //Google Analytics
            //set current path as first listen
            if (process.env.REACT_APP_USE_ANALYTICS === "true") {
                trackPage(`${this.props.location.pathname}${this.props.location.search}`);

                //listen to react-router history
                this.listener = this.props.history.listen((location: any, action: any) => {
                    trackPage(`${location.pathname}${location.search}`);
                });
            }
        }

        //set app title
        let name = process.env.REACT_APP_IS_INVENTORY === "true" ? "Refine Inventory" : "Refine Accounting";
        //title
        let title = changeCase.title(this.props.match.url.replace(/\//g, " ")) + `  - ${name}`;
        this.setState({
            helmet: (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            ),
        });
    }

    componentWillUnmount() {
        if (this.listener != null) this.listener(); //unlisten
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.match.url !== prevProps.match.url) {
            //set title etc if needed
            let name = "Refine Webview";
            let title = changeCase.title(this.props.match.url.replace(/\//g, " ")) + `  - ${name}`;
            this.setState({
                helmet: (
                    <Helmet>
                        <title>{title}</title>
                    </Helmet>
                ),
            });
        }
    }

    toggleSidebar = () => {
        localStorage.setItem("sidebarState", (!this.state.isSidebarOpen).toString());
        if (!this.state.isSidebarOpen) {
            document.body.classList.add("sidebar-open");
            document.body.classList.remove("sidebar-collapse");
        } else {
            document.body.classList.add("sidebar-collapse");
            document.body.classList.remove("sidebar-open");
        }

        this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
    };

    render() {
        const { isLoggedIn } = this.props.userLogin;
        if (isLoggedIn === false) {
            return <Redirect to={"/login"} />;
        } else {
            return (
                <>
                    {this.state.helmet}
                    <GlobalAPINotification />
                    <MainNavbar toggleSidebar={this.toggleSidebar} />
                    <Sidebar routeArr={this.state.sidebarLink} location={this.props.location} />
                    <div id="sidebar-overlay" onClick={this.toggleSidebar} />
                    <div className="content-wrapper">
                        <Suspense fallback={<p>Loading...</p>}>
                            <Switch>
                                {this.state.routeList}
                                <Route path="/my-account" component={MyAccountPage} />
                                <Redirect from="*" to="/stock-balance" />
                            </Switch>
                        </Suspense>
                    </div>
                </>
            );
        }
    }
}

function mapStateToProps(state: any) {
    return {
        userLogin: slcUserLogin(state),
        myAccount: slcFetchMyAccount(state),
    };
}

export default connect(
    mapStateToProps,
    { actSetRedirectURL, actInitConfig, actFetchMyAccount }
)(EnsureLoginPage);
