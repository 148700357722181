import React, { Component } from "react";
import ClassNames from "classnames";
import { UncontrolledTooltip, FormGroup, Label, Col } from "reactstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { Field } from "redux-form";

type InputNumberType = BaseInputType & {
    prefix?: string;
    noFormatting?: boolean;
    onUserChange?: any;
    isInteger?:boolean;
} & WithTranslation;

function reverseCurrencyFormatting(value: string, thousandSeparator: string, decimalSeparator: string) {
    const tReg = new RegExp("\\" + thousandSeparator, "g"); //because not only 1 separator
    return value.replace(tReg, "").replace(decimalSeparator, ".");
}

const decimalSeparator = ","; //need to be changed to read from some data (e.g user-token (language) or setting)
const thousandSeparator = ".";

/**
 * !!!!! WARNING !!!!!
 *
 * Don't put onChange props into this component, it will cause bug with redux-form.
 *
 * For custom onChange use onUserChange instead.
 */
class BaseInputNumber extends Component<InputNumberType, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            id: ("input" + props.input.name).replace(/[^\w]+/g, ""),
        };
    }

    /**
     * Helps solve lagging 'a bit'
     */
    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (
            this.props.input.value === nextProps.input.value &&
            this.props.input.meta !== nextProps.input.meta
        ) {
            return false;
        }

        return true;
    }

    render() {
        const {
            input,
            meta: { touched, error },
            className = "",
            label = "",
            placeholder = label,
            /*onKeyPress,*/ disabled,
            readOnly,
            horizontalInput,
            prefix,
            isRequired,
            noFormatting,
            onUserChange,
            noInfo,
            isInteger = false,
            t,
        } = this.props;

        const { onChange, onBlur, ...otherProps } = input;

        //modify value
        let value:any = 0;
        if (isInteger){
            value = input.value!=null?input.value:0;
        } else {//string
            value = input.value!=null?String(input.value).replace(/\.0+$/,''):"0";

            //we can't set the value to "0" because it will cause problem with redux-form
            //(residual value "0" when we delete a detail)
            // if (value.trim()===""){
            //     value = "-4";
            // }
        }

        return (
            <FormGroup row={horizontalInput} className={className}>
                {label && (
                    <Label for={this.state.id} /*sm={horizontalInput ? 2 : false}*/>
                        {t(label)} {isRequired ? <small>*</small> : ""}
                    </Label>
                )}
                <Col sm={horizontalInput ? 10 : false} style={{ padding: "0" }}>
                    <NumberFormat
                        type="text"
                        {...otherProps} //pass all props except onChange & onBlur
                        className={ClassNames({ "has-danger": touched && error }, "form-control")}
                        placeholder={disabled ? "" : t(placeholder)}
                        prefix={prefix}
                        disabled={disabled}
                        readOnly={readOnly}
                        id={this.state.id}
                        thousandSeparator={!noFormatting ? thousandSeparator : null}
                        decimalSeparator={decimalSeparator}
                        onValueChange={(val: any) => {
                            if (isInteger) {
                                input.onChange(val.floatValue != null ? val.floatValue : 0);
                            } else {
                                 //remove trailing zero
                                let newValue = val.value != null ? val.value?.replace(/\.0+$/,'') : "0";

                                //we can't set the value to "0" because it will cause problem with redux-form
                                //(residual value "0" when we delete a detail)
                                input.onChange(newValue);
                            }
                            
                        }} //use the string value as onChange
                        value={value} //receive the obj
                        onFocus={(event: any) => {
                            // if (Number(input.value)==null || Number(input.value)===0)
                            //     event.target.select();
                        }}
                        isNumericString={!isInteger} //numeric string
                        maxLength={28} //include . and ,
                        decimalScale={isInteger?0:4}
                        //Why we use onUserChange here instead of onChange?
                        //in NumberFormat, we use the onValueChange to handle value change (redux-form)
                        //if we wanted to have side-effect (something that happen because of USER change)
                        //but not side-effect from this.props.change() (redux-form action), we need to separate them like this
                        //if we use onChange, it will be combined with redux-form's onChange
                        //and cause problem with this.props.change() (it will be considered as user-input change)
                        onChange={(e: any) =>
                            onUserChange != null
                                ? onUserChange(
                                      reverseCurrencyFormatting(
                                          e.target.value,
                                          thousandSeparator,
                                          decimalSeparator
                                      )
                                  )
                                : null
                        }
                    />
                </Col>
                {noInfo !== true && (
                    <UncontrolledTooltip
                        className={ClassNames({ "force-hidden": !(touched && error) })}
                        placement="right"
                        target={this.state.id}
                        flip={false} //https://github.com/reactstrap/reactstrap/issues/1488
                    >
                        {t(error)}
                    </UncontrolledTooltip>
                )}
            </FormGroup>
        );
    }
}

export class ConnectedInputNumber extends Component<any, any> {
    onChange = (val:any)=>{
        if (this.props.onChange!=null)
            this.props.onChange(this.props.index, val);
    }
    render() {
        const {
            name,
            disabled
        } = this.props;

        return (
            <Field
                name={name}
                component={InputNumber}
                type="number"
                onUserChange={this.onChange}
                disabled={disabled}
            />
        );
    }
}

export const InputNumber = withTranslation()(BaseInputNumber);
// export const DebouncedInputNumber = withTranslation()(BaseDebouncedInputNumber);
