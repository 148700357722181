import axios from "axios";
import { getDataFromCachedCollection, saveIntoCacheCollection } from "./cache";

const ROOT_URL = process.env.NODE_ENV === "development" ? "/webview-api/v1" : process.env.REACT_APP_API_URL;

/**
 * Request Caching Mode
 *
 * - **NO_LOAD_NO_SAVE**: Will **not** load cache, will **not** save cache
 * - **WITH_LOAD_WITH_SAVE**: Will load cache if available, send new request if none available. Will save response data into cache
 * - **NO_LOAD_WITH_SAVE**: Will **not** load cache, always send new request. Will save response data into cache (used mainly to replace cache)
 */
export enum CACHE_MODE {
    NO_LOAD_NO_SAVE,
    WITH_LOAD_WITH_SAVE,
    NO_LOAD_WITH_SAVE,
}

/**
 * Core Authorization config. Depends on authorization method used (e.g. JWT, Cookie Token)
 * Need to be passed for all request
 */
export function getAuthHeader() {
    //requires backend v0.1.0
    return {
        Authorization: `Bearer ${localStorage.getItem("hpT")}`, //2/3 of JWT token. the 1/3 will be sent by cookie (withAuthorization:true)
    };
}

/**
 * Removes key with undefined value.
 *
 * We need to allow null value (e.g. update to remove certain selectedId)
 */
export function filterParam(param: any): any {
    if (Array.isArray(param)) {
        let newParam = [];
        for (let i = 0; i < param.length; i++) {
            if (param[i] != null || param[i] === 0 || param[i] === null) {
                newParam.push(filterParam(param[i]));
            }
        }
        return newParam;
    } else if (typeof param === "object") {
        let newParam: any = {};
        for (let key in param) {
            if (param[key] != null || param[key] === 0 || param[key] === null) {
                newParam[key] = filterParam(param[key]);
            }
        }

        if (Object.keys(newParam).length === 0) {
            return null;
        }

        return newParam;
    } else {
        return param;
    }
}

/**
 * POST request
 * @param urlPath Path URL to the request (include any :var if any, without ROOT_URL)
 * @param data JSON data (body)
 * @param config Appended axios config
 */
export function reqPost(urlPath: string, data: any, config = {}, skipFilter: boolean = false) {
    let filteredData = skipFilter ? data : filterParam(data);

    return axios.post(`${ROOT_URL}/${urlPath}`, filteredData, {
        headers: getAuthHeader(),
        withCredentials: true,
        ...config,
    });
}

/**
 * GET request
 *
 * Will be used to cache requests (e.g. {all:true} as params)
 *
 * @param urlPath Path URL to the request (include any :var if any, without ROOT_URL)
 * @param params GET parameter (obj) that will be processed by lib to (?var1=val1&&var2=val2)
 * @param useCache Enum CACHE_MODE: Use LocalStorage caching or not
 * @param config Appended axios config
 */
export function reqGet(
    urlPath: string,
    params: any,
    config: any = {},
    useCache = CACHE_MODE.NO_LOAD_NO_SAVE,
    customCacheDuration?: number
) {
    if (useCache === CACHE_MODE.WITH_LOAD_WITH_SAVE) {
        let key = urlPath; //storage key
        const cachedResult = getDataFromCachedCollection(key, params);
        if (cachedResult != null) {
            //if any cached data, return as Promise (simulate payload object)
            return new Promise<any>((res, rej) =>
                res({
                    data: cachedResult,
                })
            );
        }
    }

    let filteredParam = filterParam(params);

    //for request without cache
    let result = axios.get(`${ROOT_URL}/${urlPath}`, {
        params: filteredParam,
        headers: getAuthHeader(),
        withCredentials: true,
        ...config,
    });

    //cache the result to localStorage if allowed
    if (useCache === CACHE_MODE.WITH_LOAD_WITH_SAVE || useCache === CACHE_MODE.NO_LOAD_WITH_SAVE) {
        let key = urlPath; //storage key
        result.then((payload: any) => saveIntoCacheCollection(key, params, payload.data, customCacheDuration));
    }

    return result;
}

/**
 * PUT request
 * @param urlPath Path URL to the request (include any :var if any, without ROOT_URL)
 * @param data JSON data (body)
 * @param config Appended axios config
 */
export function reqPut(urlPath: string, data: any, config = {}) {
    let filteredData = filterParam(data);

    return axios.put(`${ROOT_URL}/${urlPath}`, filteredData, {
        headers: getAuthHeader(),
        withCredentials: true,
        ...config,
    });
}

/**
 * DELETE request
 * @param urlPath Path URL to the request (include any :var if any, without ROOT_URL)
 * @param config Appended axios config
 */
export function reqDelete(urlPath: string, config = {}) {
    return axios.delete(`${ROOT_URL}/${urlPath}`, {
        headers: getAuthHeader(),
        withCredentials: true,
        ...config,
    });
}
